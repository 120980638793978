export const solarTermsData = {
  2012: [
    { name: "立春", date: "02月04日 18:22:23" },
    { name: "雨水", date: "02月19日 14:17:35" },
    { name: "惊蛰", date: "03月05日 12:21:02" },
    { name: "春分", date: "03月20日 13:14:25" },
    { name: "清明", date: "04月04日 17:05:36" },
    { name: "谷雨", date: "04月20日 00:12:04" },
    { name: "立夏", date: "05月05日 10:19:40" },
    { name: "小满", date: "05月20日 23:15:31" },
    { name: "芒种", date: "06月05日 14:25:53" },
    { name: "夏至", date: "06月21日 07:08:48" },
    { name: "小暑", date: "07月07日 00:40:43" },
    { name: "大暑", date: "07月22日 18:00:51" },
    { name: "立秋", date: "08月07日 10:30:32" },
    { name: "处暑", date: "08月23日 01:06:50" },
    { name: "白露", date: "09月07日 13:29:00" },
    { name: "秋分", date: "09月22日 22:48:59" },
    { name: "寒露", date: "10月08日 05:11:42" },
    { name: "霜降", date: "10月23日 08:13:33" },
    { name: "立冬", date: "11月07日 08:25:56" },
    { name: "小雪", date: "11月22日 05:50:07" },
    { name: "大雪", date: "12月07日 01:18:55" },
    { name: "冬至", date: "12月21日 19:11:35" },
    { name: "小寒", date: "01月06日 06:43:54" },
    { name: "大寒", date: "01月21日 00:09:49" },
  ],
  2013: [
    { name: "立春", date: "02月04日 00:13:25" },
    { name: "雨水", date: "02月18日 20:01:35" },
    { name: "惊蛰", date: "03月05日 18:14:51" },
    { name: "春分", date: "03月20日 19:01:55" },
    { name: "清明", date: "04月04日 23:02:27" },
    { name: "谷雨", date: "04月20日 06:03:18" },
    { name: "立夏", date: "05月05日 16:18:09" },
    { name: "小满", date: "05月21日 05:09:30" },
    { name: "芒种", date: "06月05日 20:23:19" },
    { name: "夏至", date: "06月21日 13:03:56" },
    { name: "小暑", date: "07月07日 06:34:36" },
    { name: "大暑", date: "07月22日 23:55:58" },
    { name: "立秋", date: "08月07日 16:20:21" },
    { name: "处暑", date: "08月23日 07:01:41" },
    { name: "白露", date: "09月07日 19:16:16" },
    { name: "秋分", date: "09月23日 04:44:08" },
    { name: "寒露", date: "10月08日 10:58:29" },
    { name: "霜降", date: "10月23日 14:09:48" },
    { name: "立冬", date: "11月07日 14:13:52" },
    { name: "小雪", date: "11月22日 11:48:06" },
    { name: "大雪", date: "12月07日 07:08:31" },
    { name: "冬至", date: "12月22日 01:10:59" },
    { name: "小寒", date: "01月05日 12:33:37" },
    { name: "大寒", date: "01月20日 05:51:42" },
  ],
  2014: [
    { name: "立春", date: "02月04日 06:03:15" },
    { name: "雨水", date: "02月19日 01:59:29" },
    { name: "惊蛰", date: "03月06日 00:02:15" },
    { name: "春分", date: "03月21日 00:57:06" },
    { name: "清明", date: "04月05日 04:46:39" },
    { name: "谷雨", date: "04月20日 11:55:32" },
    { name: "立夏", date: "05月05日 21:59:25" },
    { name: "小满", date: "05月21日 10:59:02" },
    { name: "芒种", date: "06月06日 02:03:02" },
    { name: "夏至", date: "06月21日 18:51:13" },
    { name: "小暑", date: "07月07日 12:14:45" },
    { name: "大暑", date: "07月23日 05:41:21" },
    { name: "立秋", date: "08月07日 22:02:28" },
    { name: "处暑", date: "08月23日 12:45:58" },
    { name: "白露", date: "09月08日 01:01:25" },
    { name: "秋分", date: "09月23日 10:29:04" },
    { name: "寒露", date: "10月08日 16:47:29" },
    { name: "霜降", date: "10月23日 19:57:03" },
    { name: "立冬", date: "11月07日 20:06:40" },
    { name: "小雪", date: "11月22日 17:38:11" },
    { name: "大雪", date: "12月07日 13:04:05" },
    { name: "冬至", date: "12月22日 07:03:01" },
    { name: "小寒", date: "01月05日 18:24:10" },
    { name: "大寒", date: "01月20日 11:51:14" },
  ],
  2015: [
    { name: "立春", date: "02月04日 11:58:27" },
    { name: "雨水", date: "02月19日 07:49:47" },
    { name: "惊蛰", date: "03月06日 05:55:39" },
    { name: "春分", date: "03月21日 06:45:07" },
    { name: "清明", date: "04月05日 10:39:07" },
    { name: "谷雨", date: "04月20日 17:41:50" },
    { name: "立夏", date: "05月06日 03:52:35" },
    { name: "小满", date: "05月21日 16:44:45" },
    { name: "芒种", date: "06月06日 07:58:09" },
    { name: "夏至", date: "06月22日 00:37:53" },
    { name: "小暑", date: "07月07日 18:12:14" },
    { name: "大暑", date: "07月23日 11:30:25" },
    { name: "立秋", date: "08月08日 04:01:23" },
    { name: "处暑", date: "08月23日 18:37:15" },
    { name: "白露", date: "09月08日 06:59:33" },
    { name: "秋分", date: "09月23日 16:20:31" },
    { name: "寒露", date: "10月08日 22:42:47" },
    { name: "霜降", date: "10月24日 01:46:41" },
    { name: "立冬", date: "11月08日 01:58:36" },
    { name: "小雪", date: "11月22日 23:25:15" },
    { name: "大雪", date: "12月07日 18:53:19" },
    { name: "冬至", date: "12月22日 12:47:55" },
    { name: "小寒", date: "01月06日 00:20:32" },
    { name: "大寒", date: "01月20日 17:43:14" },
  ],
  2016: [
    { name: "立春", date: "02月04日 17:46:00" },
    { name: "雨水", date: "02月19日 13:33:41" },
    { name: "惊蛰", date: "03月05日 11:43:30" },
    { name: "春分", date: "03月20日 12:30:08" },
    { name: "清明", date: "04月04日 16:27:29" },
    { name: "谷雨", date: "04月19日 23:29:23" },
    { name: "立夏", date: "05月05日 09:41:50" },
    { name: "小满", date: "05月20日 22:36:26" },
    { name: "芒种", date: "06月05日 13:48:28" },
    { name: "夏至", date: "06月21日 06:34:09" },
    { name: "小暑", date: "07月07日 00:03:18" },
    { name: "大暑", date: "07月22日 17:30:10" },
    { name: "立秋", date: "08月07日 09:52:58" },
    { name: "处暑", date: "08月23日 00:38:26" },
    { name: "白露", date: "09月07日 12:51:02" },
    { name: "秋分", date: "09月22日 22:21:05" },
    { name: "寒露", date: "10月08日 04:33:20" },
    { name: "霜降", date: "10月23日 07:45:30" },
    { name: "立冬", date: "11月07日 07:47:38" },
    { name: "小雪", date: "11月22日 05:22:20" },
    { name: "大雪", date: "12月07日 00:41:05" },
    { name: "冬至", date: "12月21日 18:44:07" },
    { name: "小寒", date: "01月06日 06:08:21" },
    { name: "大寒", date: "01月20日 23:27:04" },
  ],
  2017: [
    { name: "立春", date: "02月03日 23:34:01" },
    { name: "雨水", date: "02月18日 19:31:16" },
    { name: "惊蛰", date: "03月05日 17:32:40" },
    { name: "春分", date: "03月20日 18:28:35" },
    { name: "清明", date: "04月04日 22:17:16" },
    { name: "谷雨", date: "04月20日 05:26:58" },
    { name: "立夏", date: "05月05日 15:30:59" },
    { name: "小满", date: "05月21日 04:30:53" },
    { name: "芒种", date: "06月05日 19:36:33" },
    { name: "夏至", date: "06月21日 12:24:06" },
    { name: "小暑", date: "07月07日 05:50:38" },
    { name: "大暑", date: "07月22日 23:15:18" },
    { name: "立秋", date: "08月07日 15:39:58" },
    { name: "处暑", date: "08月23日 06:20:09" },
    { name: "白露", date: "09月07日 18:38:34" },
    { name: "秋分", date: "09月23日 04:01:44" },
    { name: "寒露", date: "10月08日 10:22:05" },
    { name: "霜降", date: "10月23日 13:26:36" },
    { name: "立冬", date: "11月07日 13:37:45" },
    { name: "小雪", date: "11月22日 11:04:34" },
    { name: "大雪", date: "12月07日 06:32:35" },
    { name: "冬至", date: "12月22日 00:27:53" },
    { name: "小寒", date: "01月05日 11:55:42" },
    { name: "大寒", date: "01月20日 05:23:33" },
  ],
  2018: [
    { name: "立春", date: "02月04日 05:28:25" },
    { name: "雨水", date: "02月19日 01:17:57" },
    { name: "惊蛰", date: "03月05日 23:28:06" },
    { name: "春分", date: "03月21日 00:15:24" },
    { name: "清明", date: "04月05日 04:12:43" },
    { name: "谷雨", date: "04月20日 11:12:29" },
    { name: "立夏", date: "05月05日 21:25:18" },
    { name: "小满", date: "05月21日 10:14:33" },
    { name: "芒种", date: "06月06日 01:29:04" },
    { name: "夏至", date: "06月21日 18:07:12" },
    { name: "小暑", date: "07月07日 11:41:47" },
    { name: "大暑", date: "07月23日 05:00:16" },
    { name: "立秋", date: "08月07日 21:30:34" },
    { name: "处暑", date: "08月23日 12:08:30" },
    { name: "白露", date: "09月08日 00:29:37" },
    { name: "秋分", date: "09月23日 09:54:01" },
    { name: "寒露", date: "10月08日 16:14:37" },
    { name: "霜降", date: "10月23日 19:22:18" },
    { name: "立冬", date: "11月07日 19:31:39" },
    { name: "小雪", date: "11月22日 17:01:24" },
    { name: "大雪", date: "12月07日 12:25:48" },
    { name: "冬至", date: "12月22日 06:22:38" },
    { name: "小寒", date: "01月05日 17:48:41" },
    { name: "大寒", date: "01月20日 11:08:58" },
  ],
  2019: [
    { name: "立春", date: "02月04日 11:14:14" },
    { name: "雨水", date: "02月19日 07:03:51" },
    { name: "惊蛰", date: "03月06日 05:09:39" },
    { name: "春分", date: "03月21日 05:58:20" },
    { name: "清明", date: "04月05日 09:51:21" },
    { name: "谷雨", date: "04月20日 16:55:10" },
    { name: "立夏", date: "05月06日 03:02:40" },
    { name: "小满", date: "05月21日 15:59:01" },
    { name: "芒种", date: "06月06日 07:06:18" },
    { name: "夏至", date: "06月21日 23:54:09" },
    { name: "小暑", date: "07月07日 17:20:25" },
    { name: "大暑", date: "07月23日 10:50:16" },
    { name: "立秋", date: "08月08日 03:12:57" },
    { name: "处暑", date: "08月23日 18:01:53" },
    { name: "白露", date: "09月08日 06:16:46" },
    { name: "秋分", date: "09月23日 15:50:02" },
    { name: "寒露", date: "10月08日 22:05:32" },
    { name: "霜降", date: "10月24日 01:19:37" },
    { name: "立冬", date: "11月08日 01:24:15" },
    { name: "小雪", date: "11月22日 22:58:48" },
    { name: "大雪", date: "12月07日 18:18:21" },
    { name: "冬至", date: "12月22日 12:19:18" },
    { name: "小寒", date: "01月05日 23:38:52" },
    { name: "大寒", date: "01月20日 16:59:27" },
  ],
  2020: [
    { name: "立春", date: "02月04日 17:03:12" },
    { name: "雨水", date: "02月19日 12:56:53" },
    { name: "惊蛰", date: "03月05日 10:56:44" },
    { name: "春分", date: "03月20日 11:49:29" },
    { name: "清明", date: "04月04日 15:38:02" },
    { name: "谷雨", date: "04月19日 22:45:21" },
    { name: "立夏", date: "05月05日 08:51:16" },
    { name: "小满", date: "05月20日 21:49:09" },
    { name: "芒种", date: "06月05日 12:58:18" },
    { name: "夏至", date: "06月21日 05:43:33" },
    { name: "小暑", date: "07月06日 23:14:20" },
    { name: "大暑", date: "07月22日 16:36:44" },
    { name: "立秋", date: "08月07日 09:06:03" },
    { name: "处暑", date: "08月22日 23:44:48" },
    { name: "白露", date: "09月07日 12:07:54" },
    { name: "秋分", date: "09月22日 21:30:32" },
    { name: "寒露", date: "10月08日 03:55:07" },
    { name: "霜降", date: "10月23日 06:59:25" },
    { name: "立冬", date: "11月07日 07:13:46" },
    { name: "小雪", date: "11月22日 04:39:38" },
    { name: "大雪", date: "12月07日 00:09:21" },
    { name: "冬至", date: "12月21日 18:02:12" },
    { name: "小寒", date: "01月06日 05:29:59" },
    { name: "大寒", date: "01月20日 22:54:33" },
  ],
  2021: [
    { name: "立春", date: "02月03日 22:58:39" },
    { name: "雨水", date: "02月18日 18:43:49" },
    { name: "惊蛰", date: "03月05日 16:53:32" },
    { name: "春分", date: "03月20日 17:37:19" },
    { name: "清明", date: "04月04日 21:34:58" },
    { name: "谷雨", date: "04月20日 04:33:14" },
    { name: "立夏", date: "05月05日 14:47:01" },
    { name: "小满", date: "05月21日 03:36:58" },
    { name: "芒种", date: "06月05日 18:51:57" },
    { name: "夏至", date: "06月21日 11:32:00" },
    { name: "小暑", date: "07月07日 05:05:19" },
    { name: "大暑", date: "07月22日 22:26:16" },
    { name: "立秋", date: "08月07日 14:53:48" },
    { name: "处暑", date: "08月23日 05:34:48" },
    { name: "白露", date: "09月07日 17:52:46" },
    { name: "秋分", date: "09月23日 03:20:55" },
    { name: "寒露", date: "10月08日 09:38:53" },
    { name: "霜降", date: "10月23日 12:51:00" },
    { name: "立冬", date: "11月07日 12:58:37" },
    { name: "小雪", date: "11月22日 10:33:34" },
    { name: "大雪", date: "12月07日 05:56:55" },
    { name: "冬至", date: "12月21日 23:59:09" },
    { name: "小寒", date: "01月05日 11:23:17" },
    { name: "大寒", date: "01月20日 04:39:42" },
  ],
  2022: [
    { name: "立春", date: "02月04日 04:50:36" },
    { name: "雨水", date: "02月19日 00:42:50" },
    { name: "惊蛰", date: "03月05日 22:43:34" },
    { name: "春分", date: "03月20日 23:33:15" },
    { name: "清明", date: "04月05日 03:20:03" },
    { name: "谷雨", date: "04月20日 10:24:07" },
    { name: "立夏", date: "05月05日 20:25:46" },
    { name: "小满", date: "05月21日 09:22:25" },
    { name: "芒种", date: "06月06日 00:25:37" },
    { name: "夏至", date: "06月21日 17:13:40" },
    { name: "小暑", date: "07月07日 10:37:49" },
    { name: "大暑", date: "07月23日 04:06:49" },
    { name: "立秋", date: "08月07日 20:28:57" },
    { name: "处暑", date: "08月23日 11:15:59" },
    { name: "白露", date: "09月07日 23:32:07" },
    { name: "秋分", date: "09月23日 09:03:31" },
    { name: "寒露", date: "10月08日 15:22:16" },
    { name: "霜降", date: "10月23日 18:35:31" },
    { name: "立冬", date: "11月07日 18:45:18" },
    { name: "小雪", date: "11月22日 16:20:18" },
    { name: "大雪", date: "12月07日 11:46:04" },
    { name: "冬至", date: "12月22日 05:48:01" },
    { name: "小寒", date: "01月05日 17:13:54" },
    { name: "大寒", date: "01月20日 10:38:56" },
  ],
  2023: [
    { name: "小寒", date: "01月05日 23:04:39" },
    { name: "大寒", date: "01月20日 16:29:20" },
    { name: "立春", date: "02月04日 10:42:21" },
    { name: "雨水", date: "02月19日 06:34:05" },
    { name: "惊蛰", date: "03月06日 04:36:02" },
    { name: "春分", date: "03月21日 05:24:14" },
    { name: "清明", date: "04月05日 09:12:52" },
    { name: "谷雨", date: "04月20日 16:13:26" },
    { name: "立夏", date: "05月06日 02:18:34" },
    { name: "小满", date: "05月21日 15:08:59" },
    { name: "芒种", date: "06月06日 06:18:10" },
    { name: "夏至", date: "06月21日 22:57:37" },
    { name: "小暑", date: "07月07日 16:30:29" },
    { name: "大暑", date: "07月23日 09:50:15" },
    { name: "立秋", date: "08月08日 02:22:41" },
    { name: "处暑", date: "08月23日 17:01:06" },
    { name: "白露", date: "09月08日 05:26:31" },
    { name: "秋分", date: "09月23日 14:49:46" },
    { name: "寒露", date: "10月08日 21:15:23" },
    { name: "霜降", date: "10月24日 00:20:39" },
    { name: "立冬", date: "11月08日 00:35:23" },
    { name: "小雪", date: "11月22日 22:02:29" },
    { name: "大雪", date: "12月07日 17:32:44" },
    { name: "冬至", date: "12月22日 11:27:09" },
  ],
  2024: [
    { name: "小寒", date: "01月06日 04:49:09" },
    { name: "大寒", date: "01月20日 22:07:08" },
    { name: "立春", date: "02月04日 16:26:53" },
    { name: "雨水", date: "02月19日 12:12:58" },
    { name: "惊蛰", date: "03月05日 10:22:31" },
    { name: "春分", date: "03月20日 11:06:12" },
    { name: "清明", date: "04月04日 15:02:03" },
    { name: "谷雨", date: "04月19日 21:59:33" },
    { name: "立夏", date: "05月05日 08:09:51" },
    { name: "小满", date: "05月20日 20:59:17" },
    { name: "芒种", date: "06月05日 12:09:40" },
    { name: "夏至", date: "06月21日 04:50:46" },
    { name: "小暑", date: "07月06日 22:19:49" },
    { name: "大暑", date: "07月22日 15:44:11" },
    { name: "立秋", date: "08月07日 08:09:01" },
    { name: "处暑", date: "08月22日 22:54:48" },
    { name: "白露", date: "09月07日 11:11:06" },
    { name: "秋分", date: "09月22日 20:43:27" },
    { name: "寒露", date: "10月08日 02:59:43" },
    { name: "霜降", date: "10月23日 06:14:32" },
    { name: "立冬", date: "11月07日 06:19:49" },
    { name: "小雪", date: "11月22日 03:56:16" },
    { name: "大雪", date: "12月06日 23:16:47" },
    { name: "冬至", date: "12月21日 17:20:20" },
  ],
  2025: [
    { name: "小寒", date: "01月05日 10:32:31" },
    { name: "大寒", date: "01月20日 03:59:52" },
    { name: "立春", date: "02月03日 22:10:13" },
    { name: "雨水", date: "02月18日 18:06:18" },
    { name: "惊蛰", date: "03月05日 16:07:02" },
    { name: "春分", date: "03月20日 17:01:14" },
    { name: "清明", date: "04月04日 20:48:21" },
    { name: "谷雨", date: "04月20日 03:55:45" },
    { name: "立夏", date: "05月05日 13:56:57" },
    { name: "小满", date: "05月21日 02:54:23" },
    { name: "芒种", date: "06月05日 17:56:16" },
    { name: "夏至", date: "06月21日 10:42:00" },
    { name: "小暑", date: "07月07日 04:04:43" },
    { name: "大暑", date: "07月22日 21:29:11" },
    { name: "立秋", date: "08月07日 13:51:19" },
    { name: "处暑", date: "08月23日 04:33:35" },
    { name: "白露", date: "09月07日 16:51:41" },
    { name: "秋分", date: "09月23日 02:19:04" },
    { name: "寒露", date: "10月08日 08:40:57" },
    { name: "霜降", date: "10月23日 11:50:39" },
    { name: "立冬", date: "11月07日 12:03:48" },
    { name: "小雪", date: "11月22日 09:35:18" },
    { name: "大雪", date: "12月07日 05:04:20" },
    { name: "冬至", date: "12月21日 23:02:48" },
  ],
  2026: [
    { name: "小寒", date: "01月05日 16:22:53" },
    { name: "大寒", date: "01月20日 09:44:39" },
    { name: "立春", date: "02月04日 04:01:51" },
    { name: "雨水", date: "02月18日 23:51:39" },
    { name: "惊蛰", date: "03月05日 21:58:43" },
    { name: "春分", date: "03月20日 22:45:42" },
    { name: "清明", date: "04月05日 02:39:43" },
    { name: "谷雨", date: "04月20日 09:38:51" },
    { name: "立夏", date: "05月05日 19:48:27" },
    { name: "小满", date: "05月21日 08:36:28" },
    { name: "芒种", date: "06月05日 23:48:04" },
    { name: "夏至", date: "06月21日 16:24:12" },
    { name: "小暑", date: "07月07日 09:56:40" },
    { name: "大暑", date: "07月23日 03:12:48" },
    { name: "立秋", date: "08月07日 19:42:26" },
    { name: "处暑", date: "08月23日 10:18:31" },
    { name: "白露", date: "09月07日 22:40:59" },
    { name: "秋分", date: "09月23日 08:04:56" },
    { name: "寒露", date: "10月08日 14:28:59" },
    { name: "霜降", date: "10月23日 17:37:39" },
    { name: "立冬", date: "11月07日 17:51:46" },
    { name: "小雪", date: "11月22日 15:23:03" },
    { name: "大雪", date: "12月07日 10:52:14" },
    { name: "冬至", date: "12月22日 04:49:55" },
  ],
  2027: [
    { name: "小寒", date: "01月05日 22:09:39" },
    { name: "大寒", date: "01月20日 15:29:32" },
    { name: "立春", date: "02月04日 09:46:00" },
    { name: "雨水", date: "02月19日 05:33:10" },
    { name: "惊蛰", date: "03月06日 03:39:14" },
    { name: "春分", date: "03月21日 04:24:24" },
    { name: "清明", date: "04月05日 08:17:12" },
    { name: "谷雨", date: "04月20日 15:17:20" },
    { name: "立夏", date: "05月06日 01:24:53" },
    { name: "小满", date: "05月21日 14:17:55" },
    { name: "芒种", date: "06月06日 05:25:29" },
    { name: "夏至", date: "06月21日 22:10:30" },
    { name: "小暑", date: "07月07日 15:36:44" },
    { name: "大暑", date: "07月23日 09:04:20" },
    { name: "立秋", date: "08月08日 01:26:27" },
    { name: "处暑", date: "08月23日 16:13:59" },
    { name: "白露", date: "09月08日 04:28:08" },
    { name: "秋分", date: "09月23日 14:01:23" },
    { name: "寒露", date: "10月08日 20:16:46" },
    { name: "霜降", date: "10月23日 23:32:33" },
    { name: "立冬", date: "11月07日 23:38:15" },
    { name: "小雪", date: "11月22日 21:15:54" },
    { name: "大雪", date: "12月07日 16:37:21" },
    { name: "冬至", date: "12月22日 10:41:50" },
  ],
  2028: [
    { name: "小寒", date: "01月06日 03:54:19" },
    { name: "大寒", date: "01月20日 21:21:37" },
    { name: "立春", date: "02月04日 15:30:53" },
    { name: "雨水", date: "02月19日 11:25:42" },
    { name: "惊蛰", date: "03月05日 09:24:27" },
    { name: "春分", date: "03月20日 10:16:49" },
    { name: "清明", date: "04月04日 14:02:45" },
    { name: "谷雨", date: "04月19日 21:09:10" },
    { name: "立夏", date: "05月05日 07:11:51" },
    { name: "小满", date: "05月20日 20:09:28" },
    { name: "芒种", date: "06月05日 11:15:39" },
    { name: "夏至", date: "06月21日 04:01:39" },
    { name: "小暑", date: "07月06日 21:29:57" },
    { name: "大暑", date: "07月22日 14:53:38" },
    { name: "立秋", date: "08月07日 07:20:50" },
    { name: "处暑", date: "08月22日 22:00:35" },
    { name: "白露", date: "09月07日 10:21:49" },
    { name: "秋分", date: "09月22日 19:44:59" },
    { name: "寒露", date: "10月08日 02:08:10" },
    { name: "霜降", date: "10月23日 05:13:03" },
    { name: "立冬", date: "11月07日 05:26:54" },
    { name: "小雪", date: "11月22日 02:54:02" },
    { name: "大雪", date: "12月06日 22:24:19" },
    { name: "冬至", date: "12月21日 16:19:19" },
  ],
  2029: [
    { name: "小寒", date: "01月05日 09:41:34" },
    { name: "大寒", date: "01月20日 03:00:32" },
    { name: "立春", date: "02月03日 21:20:25" },
    { name: "雨水", date: "02月18日 17:07:34" },
    { name: "惊蛰", date: "03月05日 15:17:15" },
    { name: "春分", date: "03月20日 16:01:37" },
    { name: "清明", date: "04月04日 19:58:02" },
    { name: "谷雨", date: "04月20日 02:55:20" },
    { name: "立夏", date: "05月05日 13:07:24" },
    { name: "小满", date: "05月21日 01:55:30" },
    { name: "芒种", date: "06月05日 17:09:36" },
    { name: "夏至", date: "06月21日 09:47:55" },
    { name: "小暑", date: "07月07日 03:22:01" },
    { name: "大暑", date: "07月22日 20:41:43" },
    { name: "立秋", date: "08月07日 13:11:22" },
    { name: "处暑", date: "08月23日 03:51:14" },
    { name: "白露", date: "09月07日 16:11:32" },
    { name: "秋分", date: "09月23日 01:38:09" },
    { name: "寒露", date: "10月08日 07:57:45" },
    { name: "霜降", date: "10月23日 11:07:45" },
    { name: "立冬", date: "11月07日 11:16:23" },
    { name: "小雪", date: "11月22日 08:49:01" },
    { name: "大雪", date: "12月07日 04:13:25" },
    { name: "冬至", date: "12月21日 22:13:45" },
  ],
};

export const solarTermsDescData = [
  "小寒是第二十三个节气，在1月5-7日之间，太阳位于黄经285°。对于中国而言，小寒标志着开始进入一年中最寒冷的日子。根据中国的气象资料，小寒是气温是最低的的节气，只有少数年份的大寒气温低于小寒的。",
  "大寒是二十四节气之一，每年1月20日前后，太阳到达黄经300°时为大寒。大寒就是天气寒冷到了极点的意思。大寒前后是一年中最冷的季节。大寒正值三九刚过，四九之初。谚云：“三九四九冰上走”。",
  "立春，是二十四节气之一，又称“打春”。“立”是“开始”的意思，中国以立春为春季的开始，每年2月4日或5日太阳到达黄经315度时为立春，《月令七十二候集解》：“正月节，立，建始也，立夏秋冬同。",
  "雨水是24节气中的第2个节气。每年的2月19日前后，太阳黄经达330度时，是二十四节气的雨水。此时，气温回升、冰雪融化、降水增多，故取名为雨水。雨水节气一般从2月18日或19日开始，到3月4日或5日结束。雨水和谷雨、小雪 、大雪一样，都是反映降水现象的节气。",
  "惊蛰（jīng zhé），是24节气中的第三个节气。每年3月5日或6日，太阳到达黄经345度时即为惊蛰，这时气温回升较快，渐有春雷萌动，惊蛰的意思是天气回暖，春雷始鸣，惊醒蛰伏于地下冬眠的昆虫。蛰是藏的意思。",
  "春分是从每年的3月20日（或21日）开始至4月4日（或5日）结束。春分，每年公历3月20或21日，太阳到达黄经0度(春分点)时开始。这天昼夜长短平均，正当春季九十日之半，故称“春分”。",
  "清明节，又叫踏青节，按阳历来说，它是在每年的4月4日至6日之间，正是春光明媚草木吐绿的时节，也正是人们春游（古代叫踏青）的好时候，所以古人有清明踏青，并开展一系列体育活动的习俗。在古时，还有一种说法，就是“三月节”。",
  "谷雨是春季的最后一个节气，这时田中的秧苗初插、作物新种，最需要雨水的滋润，所以说“春雨贵如油”。谷雨是“雨生百谷”的意思，每年4月20日或21日太阳到达黄经30°时为谷雨。",
  "每年5月5日或5月6日是农历的立夏。“斗指东南，维为立夏，万物至此皆长大，故名立夏也。”此时，太阳黄经为45度，在天文学上，立夏表示即将告别春天，是夏日天的开始。",
  "小满是二十四节气之一，夏季的第二个节气。其含义是夏熟作物的籽粒开始灌浆饱满，但还未成熟，只是小满，还未大满。每年5月21日或22日视太阳到达黄径60°时为小满。",
  "芒种，是农作物成熟的意思。芒种是二十四节气中的第九个节气。每年的6月5日左右，太阳到达黄经75°时为芒种。",
  "每年的6月21日或22日，为夏至日。夏至这天，太阳直射地面的位置到达一年的最北端，几乎直射北回归线(北纬23°26′28″44)，北半球的白昼达最长，且越往北越长。",
  "每年7月7日或8日视太阳到达黄经105°时为小暑（slight heat）。斗指辛。太阳黄经为105°。天气已经很热，但不到是热的时候，所以叫小暑。此时，已是初伏前后。",
  "大暑，二十四节气之一。在每年的7月23日或24日，太阳到达黄经120°。大暑是一年中最热的节气，正值勤二伏前后，长江流域的许多地方，经常出现40℃高温天气。要作好防暑降温工作。这个节气雨水多，在“小暑、大暑，淹死老鼠”的谚语，要注意防汛防涝。",
  "立秋，是二十四节气中的第13个节气，每年8月8日或9日立秋。北斗指向西南。太阳黄经为135°。从这一天起秋天开始，秋高气爽，月明风清。此后，气温由最热逐渐下降。",
  "处暑，二十四节气之一。处暑节气在每年八月二十三日左右。此时太阳到达黄经150°。这时夏季火热已经到头了。暑气就要散了。它是温度下降的一个转折点。是气候变凉的象征，表示暑天终止。",
  "白露（white dews）是24节气之一，此时气温开始下降，天气转凉，早晨草木上有了露水。每年公历的9月7日前后是白露。",
  "秋分（autumnal equinox），农历二十四节气中的第十六个节气，时间一般为每年的9月22或23日。南方的气候由这一节气起才始入秋。从这一天起，阳光直射位置继续由赤道向南半球推移，北半球开始昼短夜长。依我国旧历的秋季 论，这一天刚好是秋季九十天的一半，因而称秋分。但在天文学上规定，北半球的秋天是从秋分开始的。",
  "每年10月8日或9日视太阳到达黄经195°时为寒露。寒露在二十四节气中排列十七，于每年的十月八日至九日交界。白露后，天气转凉，开始出现露水，到了寒露，则露水增多，且气温更低。此时我国有些地区会出现霜冻，北方已呈深秋景象，白云红叶，偶见早霜，南方也秋意渐浓，蝉噤荷残。",
  "每年阳历10月23日前后，太阳到达黄经210度时为二十四节气中的霜降。霜降是秋季的最后一个节气，是秋季到冬季的过渡节气。秋晚地面上散热很多，温度骤然下降到0度以下，空气中的水蒸气在地面或植物上直接凝结形成细微的冰针，有的成为六角形的霜花，色白且结构疏松。",
  "“立冬”节气在每年的11月7日或8日。习惯上，我国人民把这一天当作冬季的开始。冬，作为终了之意，是指一年的田间操作结束了，作物收割之后要收藏起来的意思。立冬一过，我国黄河中、下游地区即将结冰，我国各地农民都将陆续地转入农田水利基本建设和其他农事活动中。",
  "太阳黄经为240°。气温下降，开始降雪，但还不到大雪纷飞的时节，所以叫小雪。小雪前后，黄河流域开始降雪（南方降雪还要晚两个节气）；而北方，已进入封冻季节。",
  "大雪，在每年的12月7日或8日，其时视太阳到达黄经255度。大雪前后，黄河流域一带渐有积雪；而北方，已是“千里冰封，万里雪飘荡”的严冬了。",
  "冬至这一天，太阳黄经为270°,阳光几乎直射南回归线，我们北半球白昼最短，黑夜最长，开始进入数九寒天。天文学上规定这一天是北半球冬季的开始。而冬至以后，阳光直射位置逐渐向北移动，北半球的白天就逐渐长了。",
];

export function getDataByYear(data, year) {
  year = year || new Date().getFullYear();
  return data[year];
}

export const currentYearSolarTermsData = getDataByYear(solarTermsData);
